import { Configuration, ReleaseDto, ReleasesApiFactory } from '@/castapi';
import { apiConfig } from '@/shared/constants';
import Vue from 'vue';
import { AppLogger } from '@/logger';
import { getErrorMessage } from '@/castapi/helpers';

const logger = new AppLogger('adminReleases state');

type State = {
  releases: ReleaseDto[];
  releasesLoading: boolean;
  releasesLoadError: null;
  releaseSaving: boolean;
  releaseSaveError: null | Error;
};

const initialState = (): State => ({
  releases: [],
  releasesLoading: false,
  releasesLoadError: null,
  releaseSaving: false,
  releaseSaveError: null,
});

const getReleasesApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return ReleasesApiFactory(config);
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    RELEASES_LOADING(state) {
      state.releasesLoadError = null;
      state.releasesLoading = true;
    },
    RELEASES_LOADED(state, payload) {
      state.releases = payload;
      state.releasesLoading = false;
    },
    RELEASES_LOAD_ERROR(state, error) {
      state.releasesLoadError = getErrorMessage(error);
      state.releasesLoading = false;
    },
    RELEASE_ADD_IN_PROGRESS(state) {
      state.releaseSaving = true;
      state.releaseSaveError = null;
    },
    RELEASE_ADD_SUCCESS(state, payload) {
      state.releases.push(payload);
      state.releaseSaving = false;
    },
    RELEASE_ADD_ERROR(state, error) {
      state.releaseSaving = false;
      state.releaseSaveError = getErrorMessage(error);
    },
    RELEASE_UPDATE_IN_PROGRESS(state) {
      state.releaseSaving = true;
      state.releaseSaveError = null;
    },
    RELEASE_UPDATE_SUCCESS(state, payload) {
      const ind = state.releases.findIndex(item => item.id === payload.id);
      Vue.set(state.releases, ind, payload);
      state.releaseSaving = false;
    },
    RELEASE_UPDATE_ERROR(state, error) {
      state.releaseSaving = false;
      state.releaseSaveError = getErrorMessage(error);
    },
    RELEASE_DELETE_IN_PROGRESS(state) {
      state.releaseSaving = true;
      state.releaseSaveError = null;
    },
    RELEASE_DELETE_SUCCESS(state, id) {
      const index = state.releases.findIndex(item => item.id === id);
      state.releases.splice(index, 1);
      state.releaseSaving = false;
    },
    RELEASE_DELETE_ERROR(state, error) {
      state.releaseSaving = false;
      state.releaseSaveError = getErrorMessage(error);
    },
    RESET_SAVING_STATUS(state) {
      state.releaseSaving = false;
      state.releaseSaveError = null;
    },
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
  },
  actions: {
    async loadReleases({ commit, rootGetters }) {
      const accessToken = rootGetters['login/accessToken'];
      try {
        commit('RELEASES_LOADING');
        const response = await getReleasesApi(accessToken).releaseControllerFindAll();
        commit('RELEASES_LOADED', response.data);
      } catch (error) {
        commit('RELEASES_LOAD_ERROR', error);
        logger.captureStoreError('loadReleases', error);
      }
    },
    async addRelease({ commit, dispatch, rootGetters }, data) {
      const accessToken = rootGetters['login/accessToken'];
      commit('RELEASE_ADD_IN_PROGRESS');
      try {
        const response = await getReleasesApi(accessToken).releaseControllerCreate(data);
        commit('RELEASE_ADD_SUCCESS', response.data);
        await dispatch('loadReleases');
      } catch (error) {
        commit('RELEASE_ADD_ERROR', error);
        logger.captureStoreError('addRelease', error, { data });
      }
    },
    async updateRelease({ commit, dispatch, rootGetters }, data) {
      const accessToken = rootGetters['login/accessToken'];
      commit('RELEASE_UPDATE_IN_PROGRESS');
      try {
        await getReleasesApi(accessToken).releaseControllerUpdate(data.id, data);
        commit('RELEASE_UPDATE_SUCCESS', data);
        await dispatch('loadReleases');
      } catch (error) {
        commit('RELEASE_UPDATE_ERROR', error);
        logger.captureStoreError('updateRelease', error, { data });
      }
    },
    async removeRelease({ commit, dispatch, rootGetters }, id) {
      const accessToken = rootGetters['login/accessToken'];
      commit('RELEASE_DELETE_IN_PROGRESS');
      try {
        await getReleasesApi(accessToken).releaseControllerRemove(id);
        commit('RELEASE_DELETE_SUCCESS', id);
        await dispatch('loadReleases');
      } catch (error) {
        commit('RELEASE_DELETE_ERROR', error);
        logger.captureStoreError('removeRelease', error, { id });
      }
    },
    resetSavingStatus({ commit }) {
      commit('RESET_SAVING_STATUS');
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  getters: {
    releases: state => state.releases,
    releasesLoading: state => state.releasesLoading,
    releasesLoadError: state => state.releasesLoadError,
    releaseSaving: state => state.releaseSaving,
    releaseSaveError: state => state.releaseSaveError,
  },
};
