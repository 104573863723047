import {
  Configuration,
  DongleActivityDto,
  DongleLicenseDto,
  DongleReauthCodeDto,
  DonglesApiFactory,
  DongleUsersInfoDto,
  ProductInfoDto,
  SteppingDto,
  SteppingsApiFactory,
} from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { getErrorMessage } from '@/castapi/helpers';
import { AppLogger } from '@/logger';

const logger = new AppLogger('adminDongles state');

interface AdminDonglesState {
  dongles: DongleUsersInfoDto[];
  availableDongles: ProductInfoDto[];
  availableDonglesLoading: boolean;
  availableDonglesLoadError: null | Error;
  organizationAndUserDongles: ProductInfoDto[];
  steppingsList: SteppingDto[];
  steppingsLoading: boolean;
  steppingsLoadError: null | Error;
  allDonglesLoading: boolean;
  allDonglesLoadError: null | Error;
  organizationAndUserDonglesLoading: boolean;
  organizationAndUserDonglesLoadError: null | string;
  insertDongleError: null | Error;
  assignDongleError: null | Error;
  dongleTransferInProgress: boolean;
  dongleTransferError: null | Error;
  dongleDeleteInProgress: boolean;
  dongleDeleteError: null | Error;
  statusChangeError: null | Error;
  dongleChanging: boolean;
  renewCode: null | string;
  upgradeCode: null | string;
  upgradeTypes: [];
  currentDongle: null | Error;
  currentDongleChanging: boolean;
  currentDongleChangeError: null | Error;
  dongleActivitiesLoading: boolean;
  dongleActivitiesLoadError: null | string;
  dongleActivities: DongleActivityDto[];
  dongleLicensesLoading: boolean;
  dongleLicensesLoadError: null | string;
  dongleLicenses: DongleLicenseDto[];
  isRenewUpgrade: true;
  generatingDongleUpgradeCode: boolean;
  generateMembershipLoading: boolean;
  userDongles: [];
  userDonglesLoading: boolean;
  userDonglesLoadError: null | Error;
  dongleReauthData: null | DongleReauthCodeDto;
  dongleReauthDataLoading: boolean;
  dongleReauthDataLoadError: null | Error;
  cheatCounterResetError: null | Error;
  renewUpgradeCodeGenerationError: boolean;
}

const initialState = (): AdminDonglesState => ({
  dongles: [],
  availableDongles: [],
  availableDonglesLoading: false,
  availableDonglesLoadError: null,
  organizationAndUserDongles: [],
  steppingsList: [],
  steppingsLoading: false,
  steppingsLoadError: null,
  allDonglesLoading: false,
  allDonglesLoadError: null,
  organizationAndUserDonglesLoading: false,
  organizationAndUserDonglesLoadError: null,
  insertDongleError: null,
  generatingDongleUpgradeCode: false,
  generateMembershipLoading: false,
  assignDongleError: null,
  dongleTransferInProgress: false,
  dongleTransferError: null,
  dongleDeleteInProgress: false,
  dongleDeleteError: null,
  statusChangeError: null,
  dongleChanging: false,
  renewCode: null,
  upgradeCode: null,
  // TODO: UpgradeTypeDto[]
  upgradeTypes: [],
  currentDongle: null,
  currentDongleChanging: false,
  currentDongleChangeError: null,
  dongleActivitiesLoading: false,
  dongleActivitiesLoadError: null,
  dongleActivities: [],
  isRenewUpgrade: true,
  userDongles: [],
  userDonglesLoading: false,
  userDonglesLoadError: null,
  dongleReauthData: null,
  dongleReauthDataLoading: false,
  dongleReauthDataLoadError: null,
  cheatCounterResetError: null,
  renewUpgradeCodeGenerationError: false,
  dongleLicenses: [],
  dongleLicensesLoading: false,
  dongleLicensesLoadError: null,
});

const getDonglesApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return DonglesApiFactory(config);
};

const getSteppingsApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return SteppingsApiFactory(config);
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SELECT_DONGLE(state, dongle) {
      state.currentDongle = dongle;
    },

    STEPPINGS_LOADING(state) {
      state.steppingsLoadError = null;
      state.steppingsLoading = true;
    },

    STEPPINGS_LOADED(state, payload) {
      state.steppingsList = payload;
      state.steppingsLoadError = false;
    },

    STEPPINGS_LOAD_ERROR(state, error) {
      state.steppingsLoadError = getErrorMessage(error);
      state.steppingsLoading = false;
    },

    DONGLES_LOADING(state) {
      state.allDonglesLoadError = null;
      state.allDonglesLoading = true;
    },

    DONGLES_LOADED(state, payload) {
      state.dongles = payload;
      state.allDonglesLoading = false;
    },

    DONGLES_LOAD_ERROR(state, error) {
      state.allDonglesLoadError = getErrorMessage(error);
      state.allDonglesLoading = false;
    },

    AVAILABLE_DONGLES_LOADING(state) {
      state.availableDonglesLoadError = null;
      state.availableDonglesLoading = true;
    },

    AVAILABLE_DONGLES_LOADED(state, payload) {
      state.availableDongles = payload;
      state.availableDonglesLoading = false;
    },

    AVAILABLE_DONGLES_LOAD_ERROR(state, error) {
      state.availableDonglesLoadError = getErrorMessage(error);
      state.availableDonglesLoading = false;
    },

    ORGANIZATION_DONGLES_LOADING(state) {
      state.organizationAndUserDonglesLoadError = null;
      state.organizationAndUserDonglesLoading = true;
    },

    ORGANIZATION_DONGLES_LOADED(state, payload) {
      state.organizationAndUserDongles = payload;
      state.organizationAndUserDonglesLoading = false;
    },

    ORGANIZATION_DONGLES_LOAD_ERROR(state, error) {
      state.organizationAndUserDonglesLoadError = getErrorMessage(error);
      state.organizationAndUserDonglesLoading = false;
    },
    //---

    USER_DONGLES_LOADING(state) {
      state.userDonglesLoadError = null;
      state.userDonglesLoading = true;
    },

    USER_DONGLES_LOADED(state, payload) {
      state.organizationAndUserDongles = payload;
      state.userDonglesLoading = false;
    },

    USER_DONGLES_LOAD_ERROR(state, error) {
      state.userDonglesLoadError = getErrorMessage(error);
      state.userDonglesLoading = false;
    },
    //---
    INSERT_DONGLE_ERROR(state, error) {
      state.insertDongleError = getErrorMessage(error);
    },

    ASSIGN_DONGLE_ERROR(state, payload) {
      state.dongleChanging = null;
      state.assignDongleError = getErrorMessage(payload);
    },

    DONGLE_TRANSFER_IN_PROGRESS(state) {
      state.dongleTransferError = null;
      state.dongleTransferInProgress = true;
    },

    DONGLE_TRANSFER_SUCCESS(state) {
      state.dongleTransferInProgress = false;
    },

    DONGLE_TRANSFER_ERROR(state, error) {
      state.dongleTransferError = getErrorMessage(error);
      state.dongleTransferInProgress = false;
    },

    DONGLE_DELETE_IN_PROGRESS(state) {
      state.dongleDeleteError = null;
      state.dongleDeleteInProgress = true;
    },

    DONGLE_DELETE_SUCCESS(state) {
      state.dongleDeleteInProgress = false;
    },

    DONGLE_DELETE_ERROR(state, error) {
      state.dongleDeleteInProgress = false;
      state.dongleDeleteError = getErrorMessage(error);
    },

    CHANGE_DONGLE_STATUS_ERROR(state, payload) {
      state.dongleChanging = false;
      state.statusChangeError = getErrorMessage(payload);
    },

    CHEAT_COUNTER_RESET_ERROR(state, payload) {
      state.dongleChanging = false;
      state.cheatCounterResetError = getErrorMessage(payload);
    },

    DONGLE_CHANGING(state) {
      state.assignDongleError = null;
      state.statusChangeError = null;
      state.cheatCounterResetError = null;
      state.renewUpgradeCodeGenerationError = null;
      state.dongleChanging = true;
    },

    GENERATING_UPGRADE_CODE(state) {
      state.generatingDongleUpgradeCode = true;
    },

    GENERATED_UPGRADE_CODE(state) {
      state.generatingDongleUpgradeCode = false;
    },

    GENERATING_MEMBERSHIP(state) {
      state.generateMembershipLoading = true;
    },

    MEMBERSHIP_GENERATED(state) {
      state.generateMembershipLoading = false;
    },

    DONGLE_CHANGED(state) {
      state.dongleChanging = false;
    },

    GET_RENEW_MEMBERSHIP_CODE(state, data) {
      state.renewCode = data.code;
      const dongleIndex = state.organizationAndUserDongles.findIndex(d => d.serialNumber === data.serialNumber);
      state.organizationAndUserDongles[dongleIndex].renewCode = data.code;
    },

    GET_UPGRADE_CODE(state, data) {
      state.upgradeCode = data.code;
      const dongleIndex = state.organizationAndUserDongles.findIndex(d => d.serialNumber === data.serialNumber);
      state.organizationAndUserDongles[dongleIndex].upgradeCode = data.code;
    },

    GET_UPGRADE_TYPES(state, payload) {
      state.upgradeTypes = payload;
    },

    DONGLE_ACTIVITIES_LOADING(state) {
      state.dongleActivities = [];
      state.dongleActivitiesLoadError = null;
      state.dongleActivitiesLoading = true;
    },

    DONGLE_ACTIVITIES_LOADED(state, activities) {
      state.dongleActivities = activities;
      state.dongleActivitiesLoading = false;
    },

    DONGLE_ACTIVITIES_LOAD_ERROR(state, payload) {
      state.dongleActivitiesLoading = false;
      state.dongleActivitiesLoadError = getErrorMessage(payload);
    },

    DONGLE_LICENSES_LOADING(state) {
      state.dongleLicenses = [];
      state.dongleLicensesLoadError = null;
      state.dongleLicensesLoading = true;
    },

    DONGLE_LICENSES_LOADED(state, activities) {
      state.dongleLicenses = activities;
      state.dongleLicensesLoading = false;
    },

    DONGLE_LICENSES_LOAD_ERROR(state, payload) {
      state.dongleLicensesLoading = false;
      state.dongleLicensesLoadError = getErrorMessage(payload);
    },

    DONGLE_REAUTH_DATA_LOADING(state) {
      state.dongleReauthData = null;
      state.dongleReauthDataLoadError = null;
      state.dongleReauthDataLoading = true;
    },

    DONGLE_REAUTH_DATA_LOADED(state, data) {
      state.dongleReauthData = data;
      state.dongleReauthDataLoading = false;
    },

    DONGLE_REAUTH_DATA_LOAD_ERROR(state, payload) {
      state.dongleReauthDataLoading = false;
      state.dongleReauthDataLoadError = getErrorMessage(payload);
    },

    DONGLE_RENEW_UPGRADE_CODE_GENERATION_ERROR(state, payload) {
      state.dongleChanging = false;
      state.renewUpgradeCodeGenerationError = getErrorMessage(payload);
    },

    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },

    CLEAR_ORGANIZATION_DONGLES(state) {
      state.organizationAndUserDongles = null;
    },
  },
  actions: {
    async loadDongles({ commit, rootGetters }, payload) {
      const { limit, offset, searchText, sortBy, sortDesc } = payload || {};
      commit('DONGLES_LOADING', { limit, offset, searchText });
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAll(
          limit,
          offset,
          searchText,
          sortBy,
          sortDesc,
        );
        commit('DONGLES_LOADED', response.data);
      } catch (error) {
        commit('DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongles', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },
    async loadSteppings({ state, commit, rootGetters }) {
      if (state.steppingsList.length) {
        return;
      }
      try {
        // commit('RESET_STATE');
        commit('STEPPINGS_LOADING');
        const response = await getSteppingsApi(rootGetters['login/accessToken']).steppingControllerFindAll();
        commit('STEPPINGS_LOADED', response.data);
      } catch (error) {
        commit('STEPPINGS_LOAD_ERROR', error);
        logger.captureStoreError('loadDongles', error);
      }
    },

    async loadAvailableDongles(
      { commit, rootGetters, state },
      { offset, limit, searchText, productLineId, productLevel, licenseTypeId },
    ) {
      try {
        // commit('RESET_STATE');
        commit('AVAILABLE_DONGLES_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAllAvailable(
          limit,
          offset,
          searchText,
          undefined,
          undefined,
          productLineId,
          productLevel,
          licenseTypeId,
        );
        commit('AVAILABLE_DONGLES_LOADED', [...(offset ? state.availableDongles : []), ...response.data]);
      } catch (error) {
        commit('AVAILABLE_DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadAvailableDongles', error);
      }
    },

    async loadOrganizationDongles({ commit, rootGetters }, data) {
      try {
        // commit('RESET_STATE');
        commit('ORGANIZATION_DONGLES_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAllByOrganization(
          data,
        );
        commit('ORGANIZATION_DONGLES_LOADED', response.data);
      } catch (error) {
        commit('ORGANIZATION_DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadOrganizationDongles', error, { data });
      }
    },

    async loadUserDongles({ commit, rootGetters }, data) {
      try {
        // commit('RESET_STATE');
        commit('USER_DONGLES_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAllByUser(data);
        commit('USER_DONGLES_LOADED', response.data);
      } catch (error) {
        commit('USER_DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadUserDongles', error, { data });
      }
    },

    async loadVisibleDongles({ dispatch, rootGetters }, fromPage) {
      if (fromPage === 'organizations') {
        const organizationId = rootGetters['adminOrganizations/currentOrganizationId'];
        await dispatch('loadOrganizationDongles', organizationId);
      } else if (fromPage === 'user') {
        const userId = rootGetters['adminUsers/editingUserId'];
        await dispatch('loadUserDongles', userId);
      }
    },

    async insertDongle({ commit, rootGetters }, data) {
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerInsertDongleByAdmin(data);
      } catch (error) {
        commit('INSERT_DONGLE_ERROR', error);
        logger.captureStoreError('insertDongle', error, { data });
      }
    },

    async assignDongle({ commit, dispatch, rootGetters }, { dongleId, userId, fromPage }) {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DONGLE_CHANGING');
        await getDonglesApi(accessToken).donglesControllerAssignDongle({ dongleId, userId });
        commit('DONGLE_CHANGED');
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', dongleId);
      } catch (error) {
        commit('ASSIGN_DONGLE_ERROR', error);
        logger.captureStoreError('assignDongle', error, { dongleId, userId, fromPage });
      }
    },

    async unAssignDongle({ commit, dispatch, rootGetters }, { dongleId, fromPage }) {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DONGLE_CHANGING');
        await getDonglesApi(accessToken).donglesControllerUnAssignDongle({ dongleId });
        commit('DONGLE_CHANGED');
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', dongleId);
      } catch (error) {
        commit('ASSIGN_DONGLE_ERROR', error);
        logger.captureStoreError('unAssignDongle', error, { dongleId, fromPage });
      }
    },

    async transferDongle({ commit, dispatch, rootGetters }, { dongleId, email, fromPage }) {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DONGLE_TRANSFER_IN_PROGRESS');
        await getDonglesApi(accessToken).donglesControllerTransferDongle({ dongleId, email });
        commit('DONGLE_TRANSFER_SUCCESS');
        await dispatch('loadVisibleDongles', fromPage);
      } catch (error) {
        commit('DONGLE_TRANSFER_ERROR', error);
        logger.captureStoreError('transferDongle', error, { dongleId, fromPage });
      }
    },

    async removeDongle({ commit, dispatch, rootGetters }, { dongleId, fromPage }) {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DONGLE_DELETE_IN_PROGRESS');
        await getDonglesApi(accessToken).donglesControllerRemoveDongle({ dongleId });
        commit('DONGLE_DELETE_SUCCESS');
        await dispatch('loadVisibleDongles', fromPage);
      } catch (error) {
        commit('DONGLE_DELETE_ERROR', error);
        logger.captureStoreError('removeDongle', error, { dongleId, fromPage });
      }
    },

    async changeDongleStatus({ commit, dispatch, rootGetters }, { isLoaned, isRetired, notes, dongleId, fromPage }) {
      commit('DONGLE_CHANGING');
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerChangeDongleStatus({
          isLoaned,
          isRetired,
          notes,
          dongleId,
        });
        commit('DONGLE_CHANGED');
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', dongleId);
      } catch (error) {
        commit('CHANGE_DONGLE_STATUS_ERROR', error);
        logger.captureStoreError('changeDongleStatus', error, { isLoaned, isRetired, notes, dongleId, fromPage });
      }
    },

    async getRenewMembershipCode({ commit, dispatch, rootGetters }, { data, fromPage }) {
      commit('DONGLE_CHANGING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerRenewMembership(data);
        commit('DONGLE_CHANGED');
        commit('GET_RENEW_MEMBERSHIP_CODE', { code: response.data, serialNumber: data.serialNumber });
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', data.dongleId);
      } catch (error) {
        commit('DONGLE_RENEW_UPGRADE_CODE_GENERATION_ERROR', error);
        logger.captureStoreError('getRenewMembershipCode', error, { data, fromPage });
      }
    },

    async getRenewLeaseCode({ commit, dispatch, rootGetters }, { data, fromPage }) {
      commit('DONGLE_CHANGING');
      commit('GENERATING_MEMBERSHIP');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerRenewLease(data);
        commit('DONGLE_CHANGED');
        commit('MEMBERSHIP_GENERATED');
        commit('GET_RENEW_MEMBERSHIP_CODE', { code: response.data, serialNumber: data.serialNumber });
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', data.dongleId);
      } catch (error) {
        commit('DONGLE_RENEW_UPGRADE_CODE_GENERATION_ERROR', error);
        logger.captureStoreError('getRenewLeaseCode', error, { data, fromPage });
      }
    },

    async getUpgradeCode({ commit, dispatch, rootGetters }, { data, fromPage }) {
      commit('DONGLE_CHANGING');
      commit('GENERATING_UPGRADE_CODE');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetUpgradeCode(data);
        commit('DONGLE_CHANGED');
        commit('GENERATED_UPGRADE_CODE');
        commit('GET_UPGRADE_CODE', { code: response.data, serialNumber: data.serialNumber });
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', data.dongleId);
      } catch (error) {
        commit('DONGLE_RENEW_UPGRADE_CODE_GENERATION_ERROR', error);
        logger.captureStoreError('getUpgradeCode', error, { data, fromPage });
      }
    },

    async getUpgradeTypes({ commit, rootGetters }, data) {
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetUpgradeTypes(data);
        commit('GET_UPGRADE_TYPES', response.data);
      } catch (error) {
        logger.captureStoreError('getUpgradeTypes', error, { data });
      }
    },

    async selectDongle({ commit, dispatch }, dongle) {
      commit('SELECT_DONGLE', dongle);
      await dispatch('loadDongleActivities', dongle.dongleId);
      await dispatch('loadDongleLicenses', dongle.dongleId);
      await dispatch('loadDongleReauthData', dongle.dongleId);
    },

    async loadDongleActivities({ commit, rootGetters }, dongleId) {
      commit('DONGLE_ACTIVITIES_LOADING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetDongleActivities(
          dongleId,
        );
        commit('DONGLE_ACTIVITIES_LOADED', response.data);
      } catch (error) {
        commit('DONGLE_ACTIVITIES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongleActivities', error, { dongleId });
      }
    },

    async loadDongleLicenses({ commit, rootGetters }, dongleId) {
      commit('DONGLE_LICENSES_LOADING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetLearnDongleLicenses(
          dongleId,
        );
        commit('DONGLE_LICENSES_LOADED', response.data);
      } catch (error) {
        commit('DONGLE_LICENSES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongleLicenses', error, { dongleId });
      }
    },

    async loadDongleReauthData({ commit, rootGetters }, dongleId) {
      commit('DONGLE_REAUTH_DATA_LOADING');
      try {
        const response = await getDonglesApi(
          rootGetters['login/accessToken'],
        ).donglesControllerGetDongleLatestReauthCode(dongleId);
        commit('DONGLE_REAUTH_DATA_LOADED', response.data);
      } catch (error) {
        commit('DONGLE_REAUTH_DATA_LOAD_ERROR', error);
        logger.captureStoreError('loadDongleReauthData', error, { dongleId });
      }
    },

    async resetDongleCheatCounter({ commit, dispatch, rootGetters }, { dongleId, fromPage }) {
      commit('DONGLE_CHANGING');
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerResetDongleCheatCounter(dongleId);
        commit('DONGLE_CHANGED');
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', dongleId);
      } catch (error) {
        commit('CHEAT_COUNTER_RESET_ERROR', error);
        logger.captureStoreError('resetDongleCheatCounter', error, { dongleId, fromPage });
      }
    },

    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  getters: {
    dongles: state => state.dongles,
    steppingsList: state => state.steppingsList,
    availableDongles: state => state.availableDongles,
    organizationDongles: state => state.organizationAndUserDongles,
    allDonglesLoading: state => state.allDonglesLoading,
    allDonglesLoadError: state => state.allDonglesLoadError,
    availableDonglesLoading: state => state.availableDonglesLoading,
    availableDonglesLoadError: state => state.availableDonglesLoadError,
    organizationDonglesLoading: state => state.organizationAndUserDonglesLoading,
    organizationDonglesLoadError: state => state.organizationAndUserDonglesLoadError,
    insertDongleError: state => state.insertDongleError,
    // dongleDeleteInProgress: state => state.dongleDeleteInProgress,
    // dongleDeleteError: state => state.dongleDeleteError,
    dongleTransferInProgress: state => state.dongleTransferInProgress,
    dongleTransferError: state => state.dongleTransferError,
    dongleChanging: state => state.dongleChanging,
    generatingDongleUpgradeCode: state => state.generatingDongleUpgradeCode,
    generateMembershipLoading: state => state.generateMembershipLoading,
    statusChangeError: state => state.statusChangeError,
    upgradeTypes: state => state.upgradeTypes,
    // currentDongle: state => state.currentDongle,
    currentDongleId: state => state.currentDongle?.dongleId,
    dongleActivities: state => state.dongleActivities,
    dongleActivitiesLoading: state => state.dongleActivitiesLoading,
    dongleActivitiesLoadError: state => state.dongleActivitiesLoadError,
    userDongles: state => state.organizationAndUserDongles,
    userDonglesLoading: state => state.userDonglesLoading,
    userDonglesLoadError: state => state.userDonglesLoadError,
    dongleReauthData: state => state.dongleReauthData,
    dongleReauthDataLoading: state => state.dongleReauthDataLoading,
    dongleReauthDataLoadError: state => state.dongleReauthDataLoadError,
    cheatCounterResetError: state => state.cheatCounterResetError,
    renewUpgradeCodeGenerationError: state => state.renewUpgradeCodeGenerationError,
    dongleDeleteError: state => state.dongleDeleteError,
    dongleLicenses: state => state.dongleLicenses,
  },
};
