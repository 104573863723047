import Vue from 'vue';
import Router from 'vue-router';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import filters from './plugins/filters';

import interceptorsSetup from '@/shared/helpers/interceptor';
// Styles
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import VueClipboard from 'vue-clipboard2';
import { RawLocation, Route, VueRouter } from 'vue-router/types/router';
import snackbarPlugin from '@/plugins/snackbar';
import { AppLogger } from '@/logger';
import { init, Replay, vueRouterInstrumentation } from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import Vuelidate from 'vuelidate';
import { Component } from 'vue-property-decorator';

declare global {
  // noinspection JSUnusedGlobalSymbols
  export interface Window {
    basePath: string;
    Cypress: object;
  }
}

Component.registerHooks(['validations']);
Vue.use(Vuelidate);

if (process.env.VUE_APP_SENTRY_DSN) {
  init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'next.id.cast-soft.com', /^\//],
      }),
      new Replay(),
    ],
  });
}
const logger = new AppLogger('Client root');
const consoleErrorOriginal = console.error;
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- skipcq: JS-0323
console.error = (...data: any[]): void => {
  consoleErrorOriginal(data);
  // if (isTestEnv()) {
  //   AppLogger.showErrorOnPage(data.toString());
  // }
  logger.error('Error from browser console', data, undefined, false);
};

interceptorsSetup(store, router);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.config.productionTip = false;

const originalPush = Router.prototype.push;
Router.prototype.push = function (location: RawLocation): Promise<Route> {
  return originalPush.call<VueRouter, [RawLocation], Promise<Route>>(this, location).catch(err => err);
};

Vue.use(snackbarPlugin, { store });

new Vue({
  vuetify,
  router,
  store,
  filters: {
    filters,
  },
  render: h => h(App),
}).$mount('#app');
