import { Configuration, DownloadsApiFactory, FileDto, ReleaseForDownloadsDto } from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { AppLogger } from '@/logger';
import { downloadFileUsingUrl } from '@/shared/functions';
import { getErrorMessage } from '@/castapi/helpers';

const logger = new AppLogger('customerDownloads state');

export interface FilesItem extends FileDto {
  image: string;
  title: string;
  subtitle: string;
  url: string;
}

interface State {
  releases: ReleaseForDownloadsDto[];
  releasesLoading: boolean;
  releasesLoadError: null | Error;
  files: FileDto[];
  filesLoading: boolean;
  filesLoadError: null | Error;
  selectedRelease: FilesItem | null;
}

const initialState = (): State => ({
  releases: [],
  files: [],
  releasesLoading: false,
  releasesLoadError: null,
  filesLoading: false,
  filesLoadError: null,
  selectedRelease: null,
});

const getDownloadsApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return DownloadsApiFactory(config);
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    RELEASES_LOADING(state) {
      state.releasesLoadError = null;
      state.releasesLoading = true;
    },
    RELEASES_LOADED(state, payload) {
      state.releases = payload;
      state.releasesLoading = false;
    },
    RELEASES_LOAD_ERROR(state, error) {
      state.releasesLoadError = getErrorMessage(error);
      state.releasesLoading = false;
    },
    FILES_LOADING(state) {
      state.filesLoadError = null;
      state.filesLoading = true;
    },
    FILES_LOADED(state, payload) {
      state.files = payload;
      state.filesLoading = false;
    },
    FILES_LOAD_ERROR(state, error) {
      state.filesLoadError = getErrorMessage(error);
      state.filesLoading = false;
    },
    SELECT_RELEASE(state, release) {
      state.selectedRelease = release;
    },
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
  },
  actions: {
    async loadReleases({ commit, rootGetters }) {
      try {
        commit('RESET_STATE');
        commit('RELEASES_LOADING');
        const organizationId = rootGetters['organizations/organizationId'];
        const accessToken = rootGetters['login/accessToken'];
        const response = await getDownloadsApi(accessToken).downloadsControllerFindUserReleases(organizationId);
        commit('RELEASES_LOADED', response.data);
      } catch (error) {
        commit('RELEASES_LOAD_ERROR', error);
        logger.captureStoreError('loadReleases', error);
      }
    },

    async loadFiles({ commit, rootGetters }, releaseId) {
      try {
        commit('FILES_LOADING');
        const organizationId = rootGetters['organizations/organizationId'];
        const accessToken = rootGetters['login/accessToken'];
        const response = await getDownloadsApi(accessToken).downloadsControllerFindFilesByRelease(
          releaseId,
          organizationId,
        );
        commit('FILES_LOADED', response.data);
      } catch (error) {
        commit('FILES_LOAD_ERROR', error);
        logger.captureStoreError('loadFiles', error, { releaseId });
      }
    },

    async downloadFile({ rootGetters }, id) {
      const organizationId = rootGetters['organizations/organizationId'];
      const accessToken = rootGetters['login/accessToken'];
      const response = await getDownloadsApi(accessToken).downloadsControllerGetFileDownloadUrl(id, organizationId);
      const url = response.data;
      downloadFileUsingUrl(url);
    },

    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  getters: {
    releases: (state): ReleaseForDownloadsDto[] => state.releases,
    files: state => state.files,
    releasesLoading: state => state.releasesLoading,
    releasesLoadError: state => state.releasesLoadError,
    filesLoading: state => state.filesLoading,
    filesLoadError: state => state.filesLoadError,
    selectedRelease: state => state.selectedRelease,
  },
};
