const USE_FIRST_AND_LAST_AS_INITIALS = false;

const backgroundColors = [
  '#BFDF95', //BLACK FONT
  '#BCD6FB', //BLACK FONT
  '#FECC7C', //BLACK FONT
  '#FDBDBD', //BLACK FONT
  '#DFCCFB', //BLACK FONT
  '#D4D4D4', //BLACK FONT
  '#FFFFFF', //BLACK FONT
  '#000000', //WHITE FONT
  '#584364', //WHITE FONT
  '#8E230B', //WHITE FONT
  '#4B4B4B', //WHITE FONT
  '#6C410E', //WHITE FONT
];

export const getFullName = (firstName, lastName) => {
  if (firstName) {
    if (lastName) {
      return `${firstName} ${lastName}`;
    }
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  return 'Anonymous';
};

export const getUserFullName = (user): string => {
  return user && getFullName(user.firstName, user.lastName);
};

export const getAlphabeticOnly = word => {
  return word?.replace(/[^\s\w]+/g, '');
};

export const concatInitials = fullName => {
  let initials = getAlphabeticOnly(fullName)
    .split(' ')
    .reduce((a, c) => a + c.charAt(0), '');
  if (initials.length > 2) {
    if (USE_FIRST_AND_LAST_AS_INITIALS) {
      initials.splice(1, initials.length - 2);
    } else {
      if (initials.search(/[A-Z]/) > -1) {
        initials = initials.replace(/[^A-Z]+/g, '');
      }
    }
  }
  return initials.substring(0, 2).toUpperCase();
};

export const capitalize = (s: string) => {
  if (!s) {
    return s;
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getColor = (name?: string) => {
  const index = (name?.length || 0) % backgroundColors.length;
  return index <= 6 ? '#000000' : '#FFFFFF';
};
export const getBgColor = (name?: string) => {
  const index = (name?.length || 0) % backgroundColors.length;
  return name ? backgroundColors[index] : '#FFFFFF';
};
