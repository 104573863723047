import { Configuration } from '@/castapi/configuration';
import { apiConfig } from '@/shared/constants';
import {
  AddressDto,
  CountryDto,
  DiscountsApiFactory,
  DonglesApiFactory,
  DongleUtilsApiFactory,
  HttpExceptionDto,
  OrdersApiFactory,
  ProductsApiFactory,
  StripeApiFactory,
} from '@/castapi/api';
import { displayingAddressKeys } from '@/shared/constants/addressKeys';
import { omit } from '@/shared/functions';

export const prepareToServer = value => {
  const correctedValue = { ...value };
  delete correctedValue.createdAt;
  return correctedValue;
};

export type UserDetailsDrawerData = {
  mainInfo: boolean;
  upgradeCode: boolean;
  subscriptionCode: boolean;
};

export const getErrorMessage = (error: Error | HttpExceptionDto): string => {
  if (error instanceof Error) {
    return (error as Error).message;
  }
  if (error.status === 500) {
    return 'Something went wrong, try again later';
  }
  return error.data.message;
};

export const isKnownError = (httpException: HttpExceptionDto, code: number, pattern: string | RegExp): boolean => {
  if (httpException.status !== code) {
    return false;
  }
  if (typeof pattern === 'string') {
    return httpException.data.message === pattern;
  }
  return (pattern as RegExp).test(httpException.data.message);
};

export const getOrdersApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return OrdersApiFactory(config);
};

export const getProductsApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return ProductsApiFactory(config);
};

export const getDiscountApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return DiscountsApiFactory(config);
};

export const getDonglesApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return DonglesApiFactory(config);
};

export const getDongleUtilitiesApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return DongleUtilsApiFactory(config);
};

export const getStripeApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return StripeApiFactory(config);
};

export const addressLine = (address: Partial<AddressDto>, countries: CountryDto[]) =>
  displayingAddressKeys
    .filter(key => address[key])
    .map(key => (key === 'countryRef' ? countries.find(country => country.id === address[key])?.name : address[key]))
    .join(', ');

export const isAddressValid = function (address: AddressDto | null): boolean {
  return address
    ? displayingAddressKeys.reduce((acc, curr) => acc && (curr === 'apt' || Boolean(address[curr])), true)
    : false;
};

export const getAddress = (address: AddressDto | null): Partial<AddressDto> | null =>
  address ? displayingAddressKeys.reduce((acc, curr) => ({ ...acc, [curr]: address[curr] }), {}) : null;

export const omitUserSecretFields = user => {
  const fieldsToOmit = ['password', 'token', 'reCaptchaToken'];
  return omit(user, fieldsToOmit);
};

export interface ISearchParams {
  limit: number | undefined;
  offset: number | undefined;
  searchText: string | undefined;
  sortBy: string;
  sortDesc: boolean;
}
