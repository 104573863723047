import { Configuration, CountryDto, DictionaryApi, OrganizationsApiFactory, RegionDto, UserRoleDto } from '@/castapi';
import { apiConfig, USER_GROUP_IDS } from '@/shared/constants';
import { AppLogger } from '@/logger';
import { getErrorMessage } from '@/castapi/helpers';

// Note: CountryDto was generated with field '_native' instead of 'native'
export interface CountryWithNativeDto extends CountryDto {
  native: string;
}

const logger = new AppLogger('dictionary state');

const getDictionaryApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return new DictionaryApi(config);
};

const getOrganizationsApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return OrganizationsApiFactory(config);
};

const initialState = (): {
  countries: CountryDto[];
  countriesLoading: boolean;
  countriesLoadError: null | Error;
  regions: Record<string, RegionDto>;
  regionsLoading: boolean;
  regionsLoadError: null | Error;
  userRoleTypes: UserRoleDto[];
  userRoleTypesLoadError: null | Error;
} => ({
  countries: [],
  countriesLoading: false,
  countriesLoadError: null,
  regions: {},
  regionsLoading: false,
  regionsLoadError: null,
  userRoleTypes: [],
  userRoleTypesLoadError: null,
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    COUNTRIES_LOADING(state) {
      state.countriesLoadError = null;
      state.countriesLoading = true;
    },
    COUNTRIES_LOADED(state, countries) {
      state.countries = countries;
      state.countriesLoading = false;
    },
    COUNTRIES_LOAD_ERROR(state, error) {
      state.countriesLoadError = getErrorMessage(error);
      state.countriesLoading = false;
    },
    REGIONS_LOADING(state) {
      state.regionsLoadError = null;
      state.regionsLoading = true;
    },
    REGIONS_LOADED(state, { regions, countryId }) {
      state.regions = { ...state.regions, [countryId]: regions };
      state.regionsLoading = false;
    },
    REGIONS_LOAD_ERROR(state, error) {
      state.regionsLoadError = getErrorMessage(error);
      state.regionsLoading = false;
    },
    ROLES_LOADED(state, roles) {
      state.userRoleTypes = roles;
      state.userRoleTypesLoadError = null;
    },
    ROLES_LOAD_ERROR(state, error) {
      state.userRoleTypesLoadError = getErrorMessage(error);
    },
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    async getCountries({ state, commit }) {
      try {
        if (!state.countries.length) {
          commit('COUNTRIES_LOADING');
          const response = await getDictionaryApi().countryControllerFindAll();
          commit('COUNTRIES_LOADED', response.data);
        }
      } catch (error) {
        commit('COUNTRIES_LOAD_ERROR', error);
        logger.captureStoreError('getCountries', error);
      }
    },
    async getCountryRegions({ state, rootGetters, commit }, countryId) {
      try {
        if (!countryId || state.regions[countryId]) {
          return;
        }
        commit('REGIONS_LOADING');
        const response = await getDictionaryApi(rootGetters['login/accessToken']).regionControllerFindCountryRegions(
          countryId,
        );
        commit('REGIONS_LOADED', { countryId, regions: response.data });
      } catch (error) {
        commit('REGIONS_LOAD_ERROR', error);
        logger.captureStoreError('getCountryRegions', error, { countryId });
      }
    },
    async getUserRoles({ state, rootGetters, commit }, payload: { accessToken: string; force?: boolean }) {
      try {
        if (payload.force || !state.userRoleTypes.length) {
          const response = await getOrganizationsApi(
            payload.accessToken || rootGetters['login/accessToken'],
          ).organizationsControllerGetUserRoleTypes();
          commit('ROLES_LOADED', response.data);
        }
      } catch (error) {
        commit('ROLES_LOAD_ERROR', error);
        logger.captureStoreError('getUserRoles', error);
      }
    },
  },
  getters: {
    countries: state => state.countries,
    countriesLoading: state => state.countriesLoading,
    countriesLoadError: state => state.countriesLoadError,
    regions: state => state.regions,
    regionsLoading: state => state.regionsLoading,
    regionsLoadError: state => state.regionsLoadError,
    userRoleTypes: state => state.userRoleTypes,
    userRoleTypesLoadError: state => state.userRoleTypesLoadError,
    roleTypeGetter: (state, getters) => roleId => getters.userRoleTypes.find(type => type.userRoleId === roleId),
    staffRoleTypes: (state, getters) =>
      getters.userRoleTypes.filter(
        (role: UserRoleDto) =>
          role.userGroupRef === USER_GROUP_IDS.ADMIN /* && role.userRoleId !== USER_ROLE_IDS.SUPER_MANAGER_ROLE_ID*/,
      ),
  },
};
