import {
  BucketApiFactory,
  Configuration,
  InvoiceDto,
  InvoicesApiFactory,
  OrganizationExtendedDto,
  OrganizationsApiFactory,
  OrganizationWithOwnerDto,
  UserDto,
} from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { uploadImage } from '@/store/modules/common/Files';
import { AppLogger } from '@/logger';
import { getErrorMessage } from '@/castapi/helpers';

const logger = new AppLogger('adminOrganizations state');

const initialState = (): {
  organizations: OrganizationWithOwnerDto[];
  organizationsLoading: boolean;
  organizationsLoadError: null | string;
  currentOrganizationData: OrganizationExtendedDto | null;
  currentOrganizationLoading: boolean;
  currentOrganizationLoadError: null | string;
  invoicesList: InvoiceDto[];
  invoicesLoading: boolean;
  invoicesLoadError: null | string;
  organizationUpdating: number | null;
  organizationUpdateError: null | string;
  accountCreating: boolean;
  accountCreationError: null | string;
} => ({
  organizations: [],
  organizationsLoading: false,
  organizationsLoadError: null,
  currentOrganizationData: null,
  currentOrganizationLoading: false,
  currentOrganizationLoadError: null,
  invoicesList: [],
  invoicesLoading: false,
  invoicesLoadError: null,
  organizationUpdating: null,
  organizationUpdateError: null,
  accountCreating: false,
  accountCreationError: null,
});

const getOrganizationsApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return OrganizationsApiFactory(config);
};

const getInvoicesApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return InvoicesApiFactory(config);
};

const getBucketApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return BucketApiFactory(config);
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    ORGANIZATIONS_LOADING(state) {
      state.organizationsLoading = true;
      state.organizationsLoadError = null;
    },
    ORGANIZATIONS_LOADED(state, organizations) {
      state.organizations = organizations;
      state.organizationsLoading = false;
    },
    ORGANIZATIONS_LOAD_ERROR(state, payload) {
      state.organizationsLoading = false;
      state.organizationsLoadError = getErrorMessage(payload);
    },
    CURRENT_ORGANIZATION_LOADING(state) {
      state.currentOrganizationData = null;
      state.currentOrganizationLoading = true;
      state.currentOrganization = null;
      state.currentOrganizationLoadError = null;
    },
    CURRENT_ORGANIZATION_LOADED(state, payload) {
      state.currentOrganizationData = payload;
      state.currentOrganizationLoading = false;
    },
    CURRENT_LOAD_ERROR(state, payload) {
      state.currentOrganizationLoadError = getErrorMessage(payload);
      state.currentOrganizationLoading = false;
    },
    ORGANIZATION_UPDATING(state, organizationId) {
      state.organizationUpdating = organizationId;
      state.organizationUpdateError = null;
    },
    ORGANIZATION_UPDATED(state) {
      state.organizationUpdating = null;
    },
    ORGANIZATION_UPDATE_ERROR(state, payload) {
      state.organizationUpdateError = getErrorMessage(payload);
      state.organizationUpdating = null;
    },
    ORGANIZATION_DELETED(state) {
      state.organizationUpdating = null;
    },
    INVOICES_LOADING(state) {
      state.invoicesLoading = true;
      state.invoicesLoadError = null;
    },
    INVOICES_LOAD_ERROR(state, payload) {
      state.invoicesLoading = false;
      state.invoicesLoadError = getErrorMessage(payload);
    },
    INVOICES_LOADED(state, invoices) {
      state.invoicesLoading = false;
      state.invoicesList = invoices;
    },
    ACCOUNT_CREATING(state) {
      state.accountCreationError = null;
      state.accountCreating = true;
    },
    ACCOUNT_CREATED(state) {
      state.accountCreating = false;
    },
    ACCOUNT_CREATION_ERROR(state, payload) {
      state.accountCreating = false;
      state.accountCreationError = getErrorMessage(payload);
    },
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
    RESET_EDITOR_STATE(state) {
      state.currentOrganizationData = null;
      state.currentOrganizationLoading = false;
      state.currentOrganizationLoadError = null;
      state.invoicesList = [];
      state.invoicesLoading = false;
      state.invoicesLoadError = null;
      state.organizationUpdating = null;
      state.organizationUpdateError = null;
      state.accountCreating = false;
      state.accountCreationError = null;
    },
    CLEAR_ORGANIZATION(state) {
      state.currentOrganizationData = null;
    },
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    resetEditorState({ commit }) {
      commit('RESET_EDITOR_STATE');
    },
    async loadOrganizations({ commit, rootGetters }, payload) {
      const { limit, offset, searchText, sortBy, sortDesc } = payload || {};
      commit('ORGANIZATIONS_LOADING', { limit, offset, searchText });
      try {
        const response = await getOrganizationsApi(
          rootGetters['login/accessToken'],
        ).organizationsControllerGetAllOrganizations(limit, offset, searchText, sortBy, sortDesc);
        commit('ORGANIZATIONS_LOADED', response.data);
      } catch (error) {
        commit('ORGANIZATIONS_LOAD_ERROR', error);
        logger.captureStoreError('loadOrganizations', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },

    async getOrganizationData({ commit, rootGetters }, organizationId) {
      commit('CURRENT_ORGANIZATION_LOADING');
      try {
        const response = await getOrganizationsApi(
          rootGetters['login/accessToken'],
        ).organizationsControllerGetOrganizationWithUserRoles(organizationId);
        commit('CURRENT_ORGANIZATION_LOADED', response.data);
      } catch (error) {
        commit('CURRENT_LOAD_ERROR', error);
        logger.captureStoreError('loadOrganizations', error, { organizationId });
      }
    },

    async updateOrganization({ commit, dispatch, rootGetters }, { organizationData, avatarImage }) {
      commit('ORGANIZATION_UPDATING', organizationData.organizationId);
      try {
        const organizationAvatar = avatarImage
          ? await uploadImage(
              avatarImage,
              `organization-avatars/${organizationData.organizationId}`,
              getBucketApi(rootGetters['login/accessToken']),
            )
          : null;
        let updateBody = { ...organizationData };
        if (avatarImage !== undefined) {
          updateBody = { ...updateBody, organizationAvatar };
        }
        await getOrganizationsApi(rootGetters['login/accessToken']).organizationsControllerUpdateOrganization(
          updateBody,
        );
        commit('ORGANIZATION_UPDATED');
        await dispatch('getOrganizationData', organizationData.organizationId);
      } catch (error) {
        commit('ORGANIZATION_UPDATE_ERROR', error);
        logger.captureStoreError('updateOrganization', error, { organizationData, avatarImage });
      }
    },

    async loadInvoices({ commit, getters, rootGetters }) {
      commit('INVOICES_LOADING');
      try {
        const response = await getInvoicesApi(
          rootGetters['login/accessToken'],
        ).invoicesControllerGetOrganizationInvoices(getters.currentOrganization?.organizationId);
        commit('INVOICES_LOADED', response.data);
      } catch (error) {
        commit('INVOICES_LOAD_ERROR', error);
        logger.captureStoreError('loadInvoices', error, {
          organizationId: getters.currentOrganization?.organizationId,
        });
      }
    },
    async deleteOrganization({ commit, rootGetters }, organizationId) {
      commit('ORGANIZATION_UPDATING', organizationId);
      try {
        await getOrganizationsApi(rootGetters['login/accessToken']).organizationsControllerDeleteOrganization({
          organizationId,
        });
        commit('ORGANIZATION_DELETED');
      } catch (error) {
        commit('ORGANIZATION_UPDATE_ERROR', error);
        logger.captureStoreError('deleteOrganization', error, { organizationId });
      }
    },
    async createAccount({ commit, rootGetters }, createAccountDto) {
      commit('ACCOUNT_CREATING');
      try {
        const response = await getOrganizationsApi(
          rootGetters['login/accessToken'],
        ).organizationsControllerCreateAccountByAdmin(createAccountDto);
        // `response.data` (organizationId) is using in mutation, but not in action
        commit('ACCOUNT_CREATED', response.data);
      } catch (error) {
        commit('ACCOUNT_CREATION_ERROR', error);
        logger.captureStoreError('createAccount', error);
      }
    },
  },
  getters: {
    organizations: state => state.organizations,
    organizationsLoading: state => state.organizationsLoading,
    organizationsLoadError: state => state.organizationsLoadError,
    currentOrganizationData: state => state.currentOrganizationData,
    currentOrganization: state => state.currentOrganizationData?.organization,
    currentOrganizationId: state => state.currentOrganizationData?.organization.organizationId,
    currentOrganizationUsers: state => state.currentOrganizationData?.users || [],
    currentOrganizationMembers: state =>
      state.currentOrganizationData?.users?.filter(
        u => state.currentOrganizationData?.rolesBinding?.find(rb => rb.userRef === u.userId)?.userRoleRef === 3,
      ) || [],
    currentOrganizationMainUserRole: (state, getters, rootState, rootGetters) => {
      let priority = 0;
      let mainUserRole = null;
      state.currentOrganizationData?.rolesBinding?.forEach(binding => {
        const roleType = rootGetters['dictionary/roleTypeGetter'](binding.userRoleRef);
        if (roleType.priority >= priority) {
          mainUserRole = { ...binding, ...roleType };
          priority = roleType.priority;
        }
      });
      return mainUserRole;
    },
    currentOrganizationMainUser: (state, getters): UserDto =>
      state.currentOrganizationData?.users?.find(
        (user: UserDto) => user.userId === getters.currentOrganizationMainUserRole?.userRef,
      ),
    currentOrganizationLoading: state => state.currentOrganizationLoading,
    currentOrganizationLoadError: state => state.currentOrganizationLoadError,
    currentOrganizationAddresses: state => state.currentOrganizationData?.addresses,
    currentOrganizationShippingAddresses: (state, getters) =>
      getters.currentOrganizationAddresses?.filter(address => !address.isBilling) || [],
    currentOrganizationBillingAddresses: (state, getters) =>
      getters.currentOrganizationAddresses?.filter(address => address.isBilling) || [],
    invoices: state => state.invoicesList,
    invoicesLoading: state => state.invoicesLoading,
    invoicesLoadError: state => state.invoicesLoadError,
    // organizationUpdating: state => state.organizationUpdating,
    organizationUpdateError: state => state.organizationUpdateError,
    accountCreationError: state => state.accountCreationError,
    accountCreating: state => state.accountCreating,
  },
};
