<template>
  <v-avatar
    :size="`${size}px`"
    class="avatar"
    :style="{ 'background-color': background, border: background === '#FFFFFF' ? '1px solid #d2d2d7' : '' }"
  >
    <img v-if="getImg()" alt="Avatar" :src="getImg()" @error="onImageError()" width="64" height="64" />
    <span v-else-if="fullName" class="avatar-initials" :style="initialsSpanStyle">{{ `${initials}` || '?' }}</span>
    <v-icon v-else large>mdi-account-circle</v-icon>
    <slot></slot>
  </v-avatar>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { concatInitials, getBgColor, getColor } from '@/shared/functions';

@Component
export default class MoaAvatar extends Vue {
  @Prop({ default: null }) img: undefined | string;
  @Prop({ default: null }) fullName: undefined | string;
  @Prop({ default: 36 }) size!: number;

  private error: null | string = null;

  getImg() {
    return this.error ? undefined : this.img;
  }

  get background(): string {
    return getBgColor(this.fullName);
  }

  get font(): string {
    return `${Math.floor(this.size / 2.5)}px/${this.size}px inherit`;
  }

  get initialsSpanStyle(): Record<string, string> {
    return {
      'font-size': `${Math.floor(this.size / 2.5)}px`,
      'line-height': `${this.size}px`,
      color: getColor(this.fullName),
    };
  }

  get initials() {
    return this.fullName && concatInitials(this.fullName);
  }

  onImageError() {
    this.error = `Warning: can not load image ${this.img}`;
    console.warn(this.error);
  }
}
</script>
