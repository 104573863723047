import {
  AddressDto,
  CreateOrderDto,
  DiscountDto,
  ExpireDateResponseDto,
  HttpExceptionDto,
  OrderDto,
  OrderItemDto,
  OrderWideDto,
  ProductDto,
  ProductWideDto,
  RenewUpgradePossibilityDto,
  StripeSessionDto,
} from '@/castapi';
import { loadStripe } from '@stripe/stripe-js/pure';
import { getDiscountApi, getErrorMessage, getOrdersApi, getProductsApi, isKnownError } from '@/castapi/helpers';
import { AppLogger } from '@/logger';
import { AxiosResponse } from 'axios';

const logger = new AppLogger('OrderState');

export interface RenewUpgradePossibilityDtoWithNested
  extends Omit<RenewUpgradePossibilityDto, 'upgradeProducts' | 'renewProducts'> {
  upgradeProducts: ProductDto[];
  renewProducts: ProductDto[];
}

export interface CartItemData extends OrderItemDto, ProductDto {}

const getOrderItemProduct = (orderItem: OrderItemDto, allProducts: ProductDto[]): CartItemData => {
  const product = allProducts.find(p => p.productId === orderItem.itemProductRef);
  if (!product) {
    throw new Error(`Can not find product with id: ${orderItem.itemProductRef}`);
  }
  return { ...product, ...orderItem };
};

export type CreateOrderDongleRefAndAddressesPart = {
  existingDongleRef?: number;
  shippingAddress?:
    | (Partial<AddressDto> & {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        companyName: string;
        organizationRef: number;
        userRef: number;
      })
    | null;
  billingAddress?:
    | (Partial<AddressDto> & {
        firstName: string;
        lastName: string;
      })
    | null;
  dealerPurchaseRequest?: boolean;
};

const getOrderValue = <T>(...values: T[]): T => {
  for (const v of values) {
    if (v) {
      return v;
    }
  }
  return null as unknown as T;
};

// Plan: Solve problem - all fields can contains only value or undefined, but we trying to assign null!
const getCreateOrderDto = (
  state,
  { isAdmin, organization: org, user },
  data: CreateOrderDongleRefAndAddressesPart | null,
): CreateOrderDto => {
  const { shippingAddress, billingAddress, existingDongleRef } = { ...data };
  const order = state.order;
  return {
    orderId: getOrderValue(order.orderId),
    firstName: getOrderValue(shippingAddress?.firstName, order.firstName, !isAdmin && user.firstName),
    lastName: getOrderValue(shippingAddress?.lastName, order.lastName, !isAdmin && user.lastName),
    address1: getOrderValue(shippingAddress?.address1, order.address1),
    apt: getOrderValue(shippingAddress?.apt, order.apt),
    city: getOrderValue(shippingAddress?.city, order.city),
    province: getOrderValue(shippingAddress?.province, order.province),
    countryRef: getOrderValue(shippingAddress?.countryRef, order.countryRef),
    postalCode: getOrderValue(shippingAddress?.postalCode, order.postalCode),
    phoneNumber: getOrderValue(shippingAddress?.phoneNumber, order.phoneNumber, org?.organizationPhoneNumber),
    companyName: getOrderValue(shippingAddress?.companyName, order.companyName, org?.organizationName),
    organizationRef: getOrderValue(shippingAddress?.organizationRef, order.organizationRef, org?.organizationId),
    billingAddress1: getOrderValue(billingAddress?.address1, order.billingAddress1),
    billingApt: getOrderValue(billingAddress?.apt, order.billingApt),
    billingCity: getOrderValue(billingAddress?.city, order.billingCity),
    billingPostalCode: getOrderValue(billingAddress?.postalCode, order.billingPostalCode),
    billingCountryRef: getOrderValue(billingAddress?.countryRef, order.billingCountryRef),
    billingProvince: getOrderValue(billingAddress?.province, order.billingProvince),
    billingFirstName: getOrderValue(billingAddress?.firstName, order.billingFirstName, !isAdmin && user.firstName),
    billingLastName: getOrderValue(billingAddress?.lastName, order.billingLastName, !isAdmin && user.lastName),
    vatNumber: getOrderValue(billingAddress?.vatNumber, order.vatNumber),
    orderItems: order.orderItems,
    refPromo: getOrderValue(state.discount?.discountId, order.refPromo),
    userRef: getOrderValue(shippingAddress?.userRef, order.userRef, !isAdmin && user?.userId),
    notes: getOrderValue(order.notes),
    isRenewUpgrade: Boolean(existingDongleRef || order.existingDongleRef),
    existingDongleRef: existingDongleRef !== undefined ? existingDongleRef : order.existingDongleRef || null,
    dealerPurchaseRequest: Boolean(data?.dealerPurchaseRequest || order?.dealerPurchaseRequest),
  };
};

const validateOrder = order =>
  order.orderItems?.length &&
  order.firstName &&
  order.lastName &&
  order.userRef &&
  order.billingCountryRef &&
  order.billingProvince &&
  order.companyName &&
  order.organizationRef &&
  (order.isRenewUpgrade
    ? order.existingDongleRef
    : order.address1 && order.city && order.province && order.countryRef && order.postalCode);

export interface IOrderState {
  order: Partial<OrderWideDto> | null;
  finishedOrder: OrderDto | null;
  finishedOrderDiscount: DiscountDto | null;
  discount: DiscountDto | null;
  error: null | Error;
  isLoading: boolean;
  checkoutLoading: boolean;
  renewUpgrade: RenewUpgradePossibilityDtoWithNested;
  productsMap: Record<string, ProductWideDto[]>;
  productLoadError: null | Error;
  productsLoading: boolean;
  shopProducts: ProductWideDto[];
  expireDateLoading: boolean;
  expireDateLoadError: null | Error;
  expireDate: null | ExpireDateResponseDto;
}

const initialState: () => IOrderState = () => ({
  order: {
    orderItems: [],
    orderId: undefined,
  },
  finishedOrder: null,
  finishedOrderDiscount: null,
  discount: null,
  error: null,
  isLoading: false,
  checkoutLoading: false,
  renewUpgrade: {
    renewRequired: false,
    isTooNewForRenew: false,
    isTooOldForRenew: false,
    renewalsAvailable: 0,
    expireDateAfterMaxPossibleRenew: '',
    isUpgradePossible: false,
    renewProducts: [],
    upgradeProducts: [],
  },
  productsMap: {},
  productLoadError: null,
  productsLoading: false,
  shopProducts: [],
  expireDateLoading: false,
  expireDateLoadError: null,
  expireDate: null,
});

export default class {
  state = initialState;
  mutations = {
    LOADING(state: IOrderState) {
      state.isLoading = true;
      state.error = null;
    },
    CHECKOUT_LOADING(state: IOrderState) {
      state.checkoutLoading = true;
    },
    CHECKOUT_LOADED(state: IOrderState) {
      state.checkoutLoading = false;
    },
    // todo: state: IOrderState
    ERROR(state, payload) {
      state.isLoading = false;
      state.error = getErrorMessage(payload);
    },
    LOADED(state: IOrderState) {
      state.isLoading = false;
    },
    ORDER_LOADED(state: IOrderState, order: OrderWideDto) {
      state.isLoading = false;
      state.order = { ...state.order, ...order };
    },
    ORDER_CHANGED(state: IOrderState) {
      state.isLoading = false;
      state.order = null;
    },
    CLEAR_ORDER(state: IOrderState) {
      state.order = initialState().order;
      state.discount = initialState().discount;
      state.error = initialState().error;
    },
    CLEAR_FINISHED_ORDER(state: IOrderState) {
      state.finishedOrder = initialState().finishedOrder;
      state.finishedOrderDiscount = initialState().finishedOrderDiscount;
    },
    // todo: state: IOrderState
    FINISHED_ORDER_LOADED(state, order: OrderDto) {
      state.isLoading = false;
      state.error = false;
      state.finishedOrder = order;
      state.finishedOrderDiscount = null;
    },
    // todo: state: IOrderState
    FINISHED_ORDER_DISCOUNT_LOADED(state, discount: DiscountDto) {
      state.isLoading = false;
      state.error = false;
      state.finishedOrderDiscount = discount;
    },
    // todo: state: IOrderState
    DISCOUNT_LOADED(state, discount: DiscountDto) {
      state.isLoading = false;
      state.discount = discount;
      state.order.refPromo = discount?.discountId || null;
    },
    // todo: state: IOrderState
    DROP_DISCOUNT(state) {
      state.discount = null;
      state.order.refPromo = null;
    },
    // todo: state: IOrderState
    REMOVE_PRODUCT_FROM_CART(state, product: ProductWideDto) {
      const index = state.order.orderItems.findIndex((item: OrderItemDto) => item.itemProductRef === product.productId);
      state.order.orderItems.splice(index, 1);
    },
    // todo: state: IOrderState
    ADD_PRODUCT_TO_CART(state, addedProduct: ProductWideDto) {
      const cartItem = state.order.orderItems.find(
        (item: OrderItemDto) => item.itemProductRef === addedProduct.productId,
      );
      if (!cartItem) {
        state.order.orderItems.push({
          itemCount: 1,
          itemId: null,
          itemOrderRef: null,
          itemPrice: addedProduct.productPrice,
          itemProductRef: addedProduct.productId,
          itemDiscount: 0,
          itemTotalPrice: addedProduct.productPrice,
        });
      } else {
        cartItem.itemCount++;
      }
    },
    // todo: state: IOrderState
    CHANGE_PRODUCT_COUNT(state, { count, productId }) {
      const cartItem = state.order.orderItems.find((item: OrderItemDto) => item.itemProductRef === productId);
      cartItem.itemCount = count;
    },
    PRODUCTS_LOADED(state: IOrderState, productsMap) {
      state.productsMap = productsMap;
      state.shopProducts = Object.keys(productsMap).reduce(
        (acc, curr) => [
          ...acc,
          ...productsMap[curr].reduce(
            (poAcc, currProduct) => [
              ...poAcc,
              ...currProduct.productOptions.map(option => ({ ...currProduct, ...option })),
            ],
            [],
          ),
        ],
        new Array<ProductWideDto>(),
      );
      state.productsLoading = false;
    },
    PRODUCTS_LOADING(state: IOrderState) {
      state.productLoadError = null;
      state.productsLoading = true;
    },
    // todo: state: IOrderState
    PRODUCTS_LOAD_ERROR(state, error: Error) {
      state.productLoadError = getErrorMessage(error);
      state.productsLoading = false;
    },
    // todo: state: IOrderState
    SELECT_PRODUCT(state, product) {
      state.order.orderItems = [
        {
          itemCount: product.itemCount || 1,
          itemId: null,
          itemOrderRef: null,
          itemPrice: 0,
          itemProductRef: product.productId,
          itemDiscount: 0,
          itemTotalPrice: 0,
        },
      ];
    },
    // todo: state: IOrderState
    RENEW_UPGRADE_PRODUCTS_LOADING(state) {
      state.isLoading = true;
      state.error = false;
      state.isRenewPossible = false;
      state.isUpgradePossible = false;
      state.renewMessage = null;
      state.upgradeMessage = null;
      state.upgradeProducts = [];
      state.renewProducts = [];
    },
    RENEW_UPGRADE_PRODUCTS_LOADED(state: IOrderState, payload: RenewUpgradePossibilityDtoWithNested) {
      state.isLoading = false;
      state.renewUpgrade = payload;
    },
    EXPIRE_DATE_LOADING(state: IOrderState) {
      state.expireDateLoading = true;
      state.expireDate = null;
      state.expireDateLoadError = null;
    },
    EXPIRE_DATE_LOADED(state: IOrderState, expireDate: ExpireDateResponseDto) {
      state.expireDateLoading = false;
      state.expireDate = expireDate;
    },
    // todo: state: IOrderState
    EXPIRE_DATE_LOAD_ERROR(state, error: Error) {
      state.expireDateLoading = false;
      state.expireDateLoadError = getErrorMessage(error);
    },
  };
  actions = {
    async redirectToCheckout({ state, commit, rootGetters }) {
      const orderId = state.order.orderId;
      try {
        commit('CHECKOUT_LOADING');
        const token = rootGetters['login/accessToken'];
        const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY as string);
        if (!stripe) {
          throw new Error('Can not init `Stripe`');
        }
        const response = await getOrdersApi(token).ordersControllerCreateSession({ orderId });
        const session = response.data as StripeSessionDto;
        commit('CLEAR_ORDER');
        commit('CHECKOUT_LOADED');
        await stripe.redirectToCheckout({ sessionId: session?.id });
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('redirectToCheckout', error);
      }
    },

    async getOrder({ commit, dispatch, rootGetters }, orderId: number) {
      commit('LOADING');
      try {
        const response = await getOrdersApi(rootGetters['login/accessToken']).ordersControllerGetOrder(orderId);
        await dispatch('orderLoaded', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('getOrder', { orderId });
      }
    },

    async syncOrder({ commit, state, rootGetters }, data?) {
      const accessToken = rootGetters['login/accessToken'];
      // Note: User can add product to cart without login, but in this case we don't need to send order to server
      if (!accessToken) {
        return;
      }
      const isAdmin = rootGetters['login/isAdmin'];
      const organization = isAdmin
        ? rootGetters['adminOrganizations/currentOrganization']
        : rootGetters['organizations/organization'];
      const user = isAdmin ? rootGetters['adminOrganizations/currentOrganizationMainUser'] : rootGetters['login/user'];
      try {
        const orderToSync = getCreateOrderDto(state, { isAdmin, organization, user }, data);
        commit('LOADING');
        const response = await getOrdersApi(accessToken).ordersControllerAddOrder(orderToSync);
        commit('ORDER_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('syncOrder', error, { data });
      }
    },

    async orderLoaded({ commit, dispatch, getters }, order: OrderWideDto) {
      if (order.refPromo && !getters.discount) {
        await dispatch('loadDiscount', order.refPromo);
      }
      if (order.existingDongleRef) {
        await dispatch('getDongleRenewUpgradeOptions', order.existingDongleRef);
      }
      commit('ORDER_LOADED', order);
    },

    async getFinishedOrder({ commit, dispatch, rootGetters }, orderId: number) {
      const accessToken = rootGetters['login/accessToken'];
      if (!accessToken) {
        return;
      }
      commit('LOADING');
      try {
        const response = await getOrdersApi(accessToken).ordersControllerGetUserOrder(orderId);
        commit('FINISHED_ORDER_LOADED', response.data);
        const discountId = (response.data as OrderDto)?.refPromo;
        if (discountId) {
          await dispatch('getFinishedOrderDiscount', discountId);
        }
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('getFinishedOrder', error, { orderId });
      }
    },

    async getFinishedOrderDiscount({ commit, rootGetters }, discountId: number) {
      const accessToken = rootGetters['login/accessToken'];
      if (!accessToken) {
        return;
      }
      try {
        commit('LOADING');
        const response = await getDiscountApi(accessToken).discountsControllerFindOne(discountId);
        commit('FINISHED_ORDER_DISCOUNT_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('getFinishedOrderDiscount', error, { discountId });
      }
    },

    async applyDiscount({ state, commit, dispatch, rootGetters }, discountCode: string) {
      if (!discountCode) {
        return;
      }
      const accessToken = rootGetters['login/accessToken'];
      if (!accessToken) {
        return;
      }
      try {
        commit('DROP_DISCOUNT');
        commit('LOADING');
        const response = await getDiscountApi(accessToken).discountsControllerFindByCode(
          discountCode,
          state.order.orderId,
          state.order.dealerPurchaseRequest,
        );
        await dispatch('discountLoaded', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('applyDiscount', error, { discountCode });
      }
    },
    async discountLoaded({ state, commit, dispatch }, discount: DiscountDto) {
      commit('DISCOUNT_LOADED', discount);
      if (state.order) {
        await dispatch('syncOrder');
      }
    },
    async dropDiscount({ state, commit, dispatch }) {
      commit('DROP_DISCOUNT');
      if (state.order) {
        await dispatch('syncOrder');
      }
    },
    async loadDiscount({ commit, rootGetters }, discountId: number) {
      const accessToken = rootGetters['login/accessToken'];
      if (!accessToken) {
        return;
      }
      try {
        commit('LOADING');
        const response = await getDiscountApi(accessToken).discountsControllerFindOne(discountId);
        commit('DISCOUNT_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('loadDiscount', error, { discountId });
      }
    },

    async changeOrderNotes({ state, commit, rootGetters }, notes) {
      if (!state.order) {
        return;
      }
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('LOADING');
        const response = await getOrdersApi(accessToken).ordersControllerChangeOrderNotes({
          orderId: state.order.orderId,
          notes,
        });
        commit('ORDER_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('changeOrderNotes', error, { notes });
      }
    },

    async markAsPaid({ state, commit, rootGetters }, data) {
      if (!state.order) {
        return;
      }
      const { isFulfilled, paymentMethod, notes } = data;
      try {
        const accessToken = rootGetters['login/accessToken'];

        commit('LOADING');
        const response = await getOrdersApi(accessToken).ordersControllerMarkOrderAsPaid({
          orderId: state.order.orderId,
          isFulfilled,
          paymentMethod,
          notes,
        });
        commit('ORDER_CHANGED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('markAsPaid', error, { isFulfilled, paymentMethod, notes });
      }
    },

    async updateFulfilmentStatus({ state, commit, rootGetters }, isFulfilled: boolean) {
      if (!state.order) {
        return;
      }
      try {
        const accessToken = rootGetters['login/accessToken'];

        commit('LOADING');
        const response = await getOrdersApi(accessToken).ordersControllerUpdateOrderFulfilmentStatus({
          orderId: state.order.orderId,
          isFulfilled,
        });
        commit('ORDER_CHANGED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('markAsPaid', error, { isFulfilled });
      }
    },

    async deleteOrder({ state, commit, rootGetters }) {
      if (!state.order) {
        return;
      }
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('LOADING');
        const orderId = state.order.orderId;
        const response = await getOrdersApi(accessToken).ordersControllerDeleteOrder({ orderId });
        commit('ORDER_CHANGED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('deleteOrder', error, { orderId: state.order?.orderId });
      }
    },

    async voidOrder({ state, commit, rootGetters }) {
      if (!state.order) {
        return;
      }
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('LOADING');
        const orderId = state.order.orderId;
        const response = await getOrdersApi(accessToken).ordersControllerVoidOrder({ orderId });
        commit('ORDER_CHANGED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('voidOrder', error, { orderId: state.order?.orderId });
      }
    },

    async removePaymentIntentDataFromOrder({ commit, rootGetters }, orderId: number) {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('LOADING');
        const response = await getOrdersApi(accessToken).ordersControllerRemovePaymentIntentDataFromOrder({ orderId });
        commit('ORDER_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('removePaymentIntentDataFromOrder', error, { orderId });
      }
    },

    async removeProductFromCart({ commit, dispatch }, product: ProductWideDto) {
      commit('REMOVE_PRODUCT_FROM_CART', product);
      await dispatch('syncOrder');
    },

    async getProductsFromApi({ commit, dispatch, rootGetters }, showOutdated = false) {
      try {
        commit('PRODUCTS_LOADING');
        const response = showOutdated
          ? await getProductsApi(rootGetters['login/accessToken']).productsControllerCumulativeProductsWithOutdated()
          : await getProductsApi().productsControllerCumulativeProducts();
        commit('PRODUCTS_LOADED', response.data);
        await dispatch('getUserUnfinishedOrder');
      } catch (error) {
        commit('PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('getProductsFromApi', error);
      }
    },

    async getUserUnfinishedOrder({ commit, dispatch, getters, rootGetters }, userId?) {
      try {
        const token = rootGetters['login/accessToken'];
        if (!token || getters.cartProducts.length) {
          return;
        }
        if (!getters.allProducts.length) {
          await dispatch('getProductsFromApi', true);
          return;
        }
        commit('LOADING');
        let response: AxiosResponse<OrderDto>;
        if (userId) {
          response = await getOrdersApi(token).ordersControllerGetUserUnfinishedOrder(userId);
        } else {
          if (rootGetters['login/isAdmin'] === undefined) {
            throw new Error('Need to check `isAdmin()` before call `getUnfinishedOrder()`');
          }
          if (rootGetters['login/isAdmin']) {
            throw new Error('`getUnfinishedOrder()` can be called only for customer');
          }
          response = await getOrdersApi(token).ordersControllerGetUnfinishedOrder();
        }
        await dispatch('orderLoaded', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('getUserUnfinishedOrder', error, { userId });
      }
    },

    async addProductToCart({ state, commit, dispatch }, addedProduct: ProductWideDto) {
      if (state.order?.existingDongleRef) {
        commit('CLEAR_ORDER');
      }
      commit('ADD_PRODUCT_TO_CART', addedProduct);
      await dispatch('syncOrder');
    },

    async changeProductCount({ commit, dispatch }, { count, productId }) {
      commit('CHANGE_PRODUCT_COUNT', { count, productId });
      await dispatch('syncOrder');
    },

    async getDongleRenewUpgradeOptions({ commit, rootGetters }, dongleId: number) {
      const token = rootGetters['login/accessToken'];
      commit('RENEW_UPGRADE_PRODUCTS_LOADING');
      try {
        const response = await getProductsApi(token).productsControllerGetRenewUpgradeProducts(dongleId);
        commit('RENEW_UPGRADE_PRODUCTS_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        // Plan: check if it is a correct situation?
        if (isKnownError(error as HttpExceptionDto, 403, 'No permissions to fetch dongle info')) {
          return;
        }
        logger.captureStoreError('getDongleRenewUpgradeOptions', error, { dongleId });
      }
    },

    async selectProduct({ commit, dispatch, rootGetters }, product: ProductWideDto) {
      commit('SELECT_PRODUCT', product);
      const isAdmin = rootGetters['login/isAdmin'];
      const existingDongleRef = isAdmin
        ? rootGetters['adminDongles/currentDongleId']
        : rootGetters['dongles/currentDongleId'];
      await dispatch('syncOrder', { existingDongleRef });
    },

    async testCheckout({ state, commit, rootGetters }) {
      const orderId = state.order.orderId;
      try {
        commit('LOADING');
        const token = rootGetters['login/accessToken'];
        const response = await getOrdersApi(token).ordersControllerProcessTestOrder({ orderId });
        commit('CLEAR_ORDER');
        commit('LOADED');
        window.location.href = response.data;
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('testCheckout', error, { orderId });
      }
    },
    async getNewExpireDate({ commit, rootGetters }, { steppingRef, expiryDate, purchaseOption, duration, itemCount }) {
      commit('EXPIRE_DATE_LOADING');
      try {
        const token = rootGetters['login/accessToken'];
        const response = await getProductsApi(token).productsControllerGetNewExpireDate(
          steppingRef,
          expiryDate,
          purchaseOption,
          duration,
          itemCount,
        );
        commit('EXPIRE_DATE_LOADED', response.data);
      } catch (error) {
        commit('EXPIRE_DATE_LOAD_ERROR', error);
        logger.captureStoreError('getNewExpireDate', error, {
          steppingRef,
          expiryDate,
          purchaseOption,
          duration,
          itemCount,
        });
      }
    },
  };
  getters = {
    order: (state: IOrderState) => state.order,
    orderId: (state: IOrderState) => state.order?.orderId,
    // todo: state: IOrderState
    orderProductsPrice: state =>
      state.order?.orderItems?.reduce((acc, curr) => acc + curr.itemCount * curr.itemPrice, 0) || 0,
    // todo: state: IOrderState
    orderProductsTotal: state =>
      state.order?.orderItems?.reduce((acc, curr) => acc + curr.itemCount * curr.itemTotalPrice, 0) || 0,
    // todo: state: IOrderState
    orderDiscountTotal: state =>
      state.order?.orderItems?.reduce((acc, curr) => acc + curr.itemCount * curr.itemDiscount, 0) || 0,
    finishedOrder: (state: IOrderState) => state.finishedOrder,
    finishedOrderProducts: (state: IOrderState, getters) =>
      state.finishedOrder?.orderItems?.map(item => getOrderItemProduct(item, getters.allProductsForFinishedOrder)),
    // finishedOrderProductsTotal: (state: IOrderState) =>
    //   state.finishedOrder?.orderItems?.reduce((acc, curr) => acc + curr.itemCount * curr.itemTotalPrice, 0) || 0,
    // todo: state: IOrderState
    finishedOrderProductsPrice: state =>
      state.finishedOrder?.orderItems?.reduce((acc, curr) => acc + curr.itemCount * curr.itemPrice, 0) || 0,
    isFinishedOrderRenewUpgrade: (state: IOrderState) => state.finishedOrder?.isRenewUpgrade,
    isFinishedOrderShippable: (state: IOrderState) => state.finishedOrder?.shippable,
    discountPromoCode: (state: IOrderState) => state.discount?.promoCode,
    finishedOrderDiscountPromoCode: (state: IOrderState) => state.finishedOrderDiscount?.promoCode,
    discount: (state: IOrderState) => state.discount,
    isLoading: (state: IOrderState) => state.isLoading,
    checkoutLoading: (state: IOrderState) => state.checkoutLoading,
    error: (state: IOrderState) => state.error,

    cartProducts: (state: IOrderState, getters) =>
      state.order?.orderItems?.map(item => getOrderItemProduct(item, getters.allProducts)) || [],
    cartProductsCount: (state: IOrderState, getters) =>
      getters.cartProducts.reduce((acc, curr) => acc + curr.itemCount, 0),
    productsLoading: (state: IOrderState) => state.productsLoading,
    productsLoadError: (state: IOrderState) => state.productLoadError,
    productTypes: (state: IOrderState) => (state?.productsMap ? Object.keys(state.productsMap) : []),
    productsMap: (state: IOrderState) => state.productsMap,
    shopProducts: (state: IOrderState) => state.shopProducts,
    currentDongleRenewUpgradeOptions: (state: IOrderState) => [
      ...state.renewUpgrade.upgradeProducts,
      ...state.renewUpgrade.renewProducts,
    ],
    currentDongleRenewOptions: (state: IOrderState) => state.renewUpgrade.renewProducts,
    currentDongleUpgradeOptions: (state: IOrderState) => state.renewUpgrade.upgradeProducts,
    currentDongleRenewRequired: (state: IOrderState) => state.renewUpgrade.renewRequired,
    currentDongleLicenseTooNewForRenew: (state: IOrderState) => state.renewUpgrade.isTooNewForRenew,
    currentDongleLicenseTooOldForRenew: (state: IOrderState) => state.renewUpgrade.isTooOldForRenew,
    currentDongleRenewalsAvailable: (state: IOrderState) => state.renewUpgrade.renewalsAvailable,
    currentDongleExpireDateAfterMaxPossibleRenew: (state: IOrderState) =>
      state.renewUpgrade.expireDateAfterMaxPossibleRenew,
    currentDongleUpgradePossibility: (state: IOrderState) => state.renewUpgrade.isUpgradePossible,
    allProducts: (state: IOrderState, getters) =>
      state.order?.existingDongleRef ? getters.currentDongleRenewUpgradeOptions : getters.shopProducts,
    allProductsForFinishedOrder: (state: IOrderState, getters) =>
      state.finishedOrder?.existingDongleRef ? getters.currentDongleRenewUpgradeOptions : getters.shopProducts,
    isOrderRenewUpgrade: (state: IOrderState) => Boolean(state.order?.existingDongleRef),
    orderValid: (state: IOrderState) => validateOrder(state.order),
    tax: (state: IOrderState) => state.order?.tax || 0,
    shipping: (state: IOrderState) => state.order?.shipping || 0,
    notes: (state: IOrderState) => state.order?.notes,
    grandTotalPrice: (state: IOrderState, getters) =>
      getters.orderProductsPrice + getters.tax + getters.shipping - getters.orderDiscountTotal,
    expireDateLoading: (state: IOrderState) => state.expireDateLoading,
    expireDateLoadError: (state: IOrderState) => state.expireDateLoadError,
    expireDate: (state: IOrderState) => state.expireDate,
    isOrderShippable: (state: IOrderState, getters) => getters.cartProducts.some(product => product.shippable),
  };
}
