import { AppLogger } from '@/logger';
import {
  Configuration,
  CreateSupportRequestDto,
  ShareFileApiFactory,
  SupportRequestApiFactory,
  SupportRequestCategoryDto,
  SupportRequestDepartmentDto,
  SupportRequestFileDto,
  SupportRequestSubcategoryDto,
  SupportRequestTypeDto,
} from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { getErrorMessage } from '@/castapi/helpers';

const logger = new AppLogger('support state');

const getSupportRequestApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return SupportRequestApiFactory(config);
};

const getSharedFileApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return ShareFileApiFactory(config);
};

const initialState = (): {
  isLoading: boolean;
  error: string | null;
  supportRequestTypes: SupportRequestTypeDto[];
  supportRequestDepartments: SupportRequestDepartmentDto[];
  supportRequestCategories: SupportRequestCategoryDto[];
  supportRequestSubcategories: SupportRequestSubcategoryDto[];
  supportRequestFiles: SupportRequestFileDto[];
} => ({
  isLoading: false,
  error: null,
  supportRequestTypes: [],
  supportRequestDepartments: [],
  supportRequestCategories: [],
  supportRequestSubcategories: [],
  supportRequestFiles: [],
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    ERROR(state, error) {
      state.error = getErrorMessage(error);
      state.isLoading = false;
    },
    SUPPORT_REQUEST_TYPES_LOADING(state) {
      state.error = null;
      state.isLoading = true;
      state.supportRequestTypes = [];
    },
    SUPPORT_REQUEST_DEPARTMENTS_LOADING(state) {
      state.error = null;
      state.isLoading = true;
      state.supportRequestDepartments = [];
    },
    SUPPORT_REQUEST_CATEGORIES_LOADING(state) {
      state.error = null;
      state.isLoading = true;
      state.supportRequestCategories = [];
    },
    SUPPORT_REQUEST_SUBCATEGORIES_LOADING(state) {
      state.error = null;
      state.isLoading = true;
      state.supportRequestSubcategories = [];
    },
    SUPPORT_REQUEST_TYPES_LOADED(state, payload) {
      state.isLoading = false;
      state.supportRequestTypes = payload;
    },
    SUPPORT_REQUEST_DEPARTMENTS_LOADED(state, payload) {
      state.isLoading = false;
      state.supportRequestDepartments = payload;
    },
    SUPPORT_REQUEST_CATEGORIES_LOADED(state, payload) {
      state.isLoading = false;
      state.supportRequestCategories = payload;
    },
    SUPPORT_REQUEST_SUBCATEGORIES_LOADED(state, payload) {
      state.isLoading = false;
      state.supportRequestSubcategories = payload;
    },
    SUPPORT_REQUEST_CREATING(state) {
      state.error = null;
      state.isLoading = true;
    },
    SUPPORT_REQUEST_CREATED(state) {
      state.isLoading = false;
    },
    SUPPORT_REQUEST_FILE_LOADING(state) {
      state.error = null;
      state.isLoading = true;
    },
    SUPPORT_REQUEST_FILE_LOADED(state, payload) {
      state.isLoading = false;
      state.supportRequestFiles = [...state.supportRequestFiles, payload];
    },
  },
  actions: {
    async loadSupportRequestTypes({ commit, rootGetters }) {
      try {
        commit('SUPPORT_REQUEST_TYPES_LOADING');
        const accessToken = rootGetters['login/accessToken'];
        const response = await getSupportRequestApi(accessToken).supportRequestControllerGetSupportRequestTypes();
        commit('SUPPORT_REQUEST_TYPES_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('loadSupportRequestTypes', error);
      }
    },

    async loadSupportRequestDepartments({ commit, rootGetters }) {
      try {
        commit('SUPPORT_REQUEST_DEPARTMENTS_LOADING');
        const accessToken = rootGetters['login/accessToken'];
        const response = await getSupportRequestApi(accessToken).supportRequestControllerGetSupportRequestDepartments();
        commit('SUPPORT_REQUEST_DEPARTMENTS_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('loadSupportRequestDepartments', error);
      }
    },

    async loadSupportRequestCategories({ commit, rootGetters }) {
      try {
        commit('SUPPORT_REQUEST_CATEGORIES_LOADING');
        const accessToken = rootGetters['login/accessToken'];
        const response = await getSupportRequestApi(accessToken).supportRequestControllerGetSupportRequestCategories();
        commit('SUPPORT_REQUEST_CATEGORIES_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('loadSupportRequestCategories', error);
      }
    },

    async loadSupportRequestSubcategories({ commit, rootGetters }) {
      try {
        commit('SUPPORT_REQUEST_SUBCATEGORIES_LOADING');
        const accessToken = rootGetters['login/accessToken'];
        const response = await getSupportRequestApi(
          accessToken,
        ).supportRequestControllerGetSupportRequestSubcategories();
        commit('SUPPORT_REQUEST_SUBCATEGORIES_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('loadSupportRequestSubcategories', error);
      }
    },

    async createSupportRequest({ commit, rootGetters }, request: CreateSupportRequestDto) {
      try {
        commit('SUPPORT_REQUEST_CREATING');
        const accessToken = rootGetters['login/accessToken'];
        await getSupportRequestApi(accessToken).supportRequestControllerCreateSupportRequest(request);
        commit('SUPPORT_REQUEST_CREATED');
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('createSupportRequest', error);
      }
    },

    async uploadFileToFileShare({ commit, rootGetters }, payload: { file: File; path: string }) {
      try {
        commit('SUPPORT_REQUEST_FILE_LOADING');
        const accessToken = rootGetters['login/accessToken'];
        const response = await getSharedFileApi(accessToken).shareFileControllerUploadFile(payload);
        commit('SUPPORT_REQUEST_FILE_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('uploadFileToFileShare', error);
      }
    },
  },

  getters: {
    isLoading: state => state.isLoading,
    error: state => state.error,
    supportRequestTypes: state => state.supportRequestTypes,
    supportRequestDepartments: state => state.supportRequestDepartments,
    supportRequestCategories: state => state.supportRequestCategories,
    supportRequestSubcategories: state => state.supportRequestSubcategories,
    supportRequestFiles: state => state.supportRequestFiles,
  },
};
